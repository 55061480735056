<template>
  <div>
    <div>
      <template>
          <v-container>
              <h4 v-if="!isNepaliLanguage" class="headline mt-5 primary--text text-center">
                  <strong> {{ branchInformation.branchName }} </strong>
              </h4>
              <h4 v-else class="headline mt-5 primary--text text-center">
                  <strong>  {{ branchInformation.branchNameNepali }}  </strong>
              </h4>
              <div id="MainContainer">
                  <v-row>
                      <v-col cols="12" sm="10">
                          <v-card class="mt-5"
                                  id="slider-card"
                                  max-height="550"
                                  width="100%"
                                  :elevation="24"
                                  style="background:white">
                              <img :src="branchInformation.logo"
                                   id="hotellogo" />
                          </v-card>
                      </v-col>
                      <v-col class="box" cols="12" sm="2">
                          <v-row class="mt-10">
                              <v-card id="box">
                                  <v-col cols="12" sm="12" class="mt-2">
                                      <v-card id="sideMainBox" class="box"
                                              @click="$router.push('/branch')"
                                              color="#077dfa"
                                              elevation="5">
                                          <p id="titleForBox">Profile</p>
                                          <v-row>
                                              <v-col cols="12" sm="4">
                                                  <v-list-item three-line>
                                                      <v-list-item-avatar>
                                                          <v-icon class="boxIcon" color="white">people</v-icon>
                                                      </v-list-item-avatar>
                                                  </v-list-item>
                                              </v-col>
                                              <v-col cols="12" sm="8">
                                                  <v-list-item three-line style="padding:0 5px;">
                                                      <v-list-item-content class="text-left">
                                                          <div>
                                                              <p id="sideBox">
                                                                  {{$t('profile')}}
                                                              </p>
                                                          </div>
                                                      </v-list-item-content>
                                                  </v-list-item>
                                              </v-col>
                                          </v-row>
                                      </v-card>
                                  </v-col>
                                  <v-col cols="12" sm="12" class="mt-2">
                                      <v-card id="sideMainBox"
                                              class="box"
                                              @click="$router.push('/PilgrimageService')"
                                              color="#006400"
                                              elevation="5">
                                          <p id="titleForBox">Ticket Category</p>
                                          <v-row>
                                              <v-col cols="12" sm="4">
                                                  <v-list-item three-line>
                                                      <v-list-avatar>
                                                          <v-icon class="boxIcon" color="white">people</v-icon>
                                                      </v-list-avatar>
                                                  </v-list-item>
                                              </v-col>
                                              <v-col cols="12" sm="8">
                                                  <v-list-item three-line style="padding:0 5px;">
                                                      <v-list-item-content class="text-left">
                                                          <div>
                                                              <p id="sideBox">
                                                                  {{$t('ticket_category')}}
                                                              </p>
                                                          </div>
                                                      </v-list-item-content>
                                                  </v-list-item>
                                              </v-col>
                                          </v-row>
                                      </v-card>
                                  </v-col>
                                  <v-col cols="12" sm="12" class="mt-2">
                                      <v-card id="sideMainBox"
                                              @click="$router.push('/PilgrimageCustomerList')"
                                              color="#FFA500"
                                              elevation="5">
                                          <p id="titleForBox">Ticket Issue</p>
                                          <v-row>
                                              <v-col cols="12" sm="4">
                                                  <v-list-item three-line>
                                                      <v-list-item-avatar>
                                                          <v-icon class="boxIcon" color="white">directions_run</v-icon>
                                                      </v-list-item-avatar>
                                                  </v-list-item>
                                              </v-col>
                                              <v-col cols="12" sm="8">
                                                  <v-list-item three-line style="padding:0 5px;">
                                                      <v-list-item-content class="text-left">
                                                          <div>
                                                              <p id="sideBox">
                                                                  {{$t('ticket_issue')}}
                                                              </p>
                                                          </div>
                                                      </v-list-item-content>
                                                  </v-list-item>
                                              </v-col>
                                          </v-row>
                                      </v-card>
                                  </v-col>
                                  <v-col cols="12" sm="12" class="mt-2">
                                      <v-card id="sideMainBox"
                                              @click="$router.push('/PilgrimageEmployeeList')"
                                              color="#2a6a6e"
                                              elevation="5">
                                          <p id="titleForBox">Employees</p>
                                          <v-row>
                                              <v-col cols="12" sm="4">
                                                  <v-list-item three-line>
                                                      <v-list-item-avatar>
                                                          <v-icon class="boxIcon" color="white">people</v-icon>
                                                      </v-list-item-avatar>
                                                  </v-list-item>
                                              </v-col>
                                              <v-col cols="12" sm="8">
                                                  <v-list-item three-line style="padding:0 5px;">
                                                      <v-list-item-content class="text-left">
                                                          <div>
                                                              <p id="sideBox">
                                                                  {{$t('employee')}}
                                                              </p>
                                                          </div>
                                                      </v-list-item-content>
                                                  </v-list-item>
                                              </v-col>
                                          </v-row>
                                      </v-card>
                                  </v-col>
                              </v-card>
                          </v-row>
                      </v-col>
                  </v-row>
              </div>
              <v-container>
                  <h4 v-if="!isNepaliLanguage" class="headline mt-10 text-center">
                      Visitors in {{ branchInformation.branchName }}
                  </h4>
                  <h4 v-else class="headline mt-10 text-center">
                      {{ branchInformation.branchNameNepali }}मा आगन्तुकहरू
                  </h4>
                  <v-row>
                      <v-col cols="12" md="12" class="mt-3">
                          <v-card elevation="5">
                              <template>
                                  <div id="app">
                                      <GChart type="ColumnChart"
                                              :data="barsData"
                                              :options="chartOptions"
                                              style="height: 400px; " />
                                  </div>
                              </template>
                          </v-card>
                      </v-col>
                  </v-row>
              </v-container>
              <hr style="margin-top: 60px;" />
              <!--<v-row>
      <v-col cols="12" md="6">
        <h1 class="headline mt-5 primary--text" style="margin-left:20px">
          {{ branchInformation.branchName }} Visitors
        </h1>
      </v-col>
      <v-col cols="12" md="6">
        <h1 class="headline mt-5 primary--text">
          {{ branchInformation.branchName }} Employees
        </h1>
      </v-col>
    </v-row>-->
              <v-container>
                  <v-row>
                      <v-col cols="12" md="6">
                          <h4 v-if="!isNepaliLanguage" class="headline mt-5 primary--text" style="margin-left:20px">
                              Customers in {{ branchInformation.branchName }}
                          </h4>
                          <h4 v-else class="headline mt-5 primary--text" style="margin-left:20px">
                              {{ branchInformation.branchNameNepali }}मा आगन्तुकहरू
                          </h4>
                          <v-card>
                              <v-list style="background-color: #1976d2">
                                  <v-list-item>
                                      <v-list-item-avatar>
                                          <v-title id="table" class="white--text"><strong>{{ $t("s_no") }}</strong></v-title>
                                      </v-list-item-avatar>
                                      <v-list-item-content align="center">
                                          <v-list-item-title id="table" class="white--text">
                                              <strong>{{ $t("name") }}</strong>
                                          </v-list-item-title>
                                      </v-list-item-content>
                                      <v-list-item-content align="center">
                                          <v-list-item-title id="table" class=" white--text">
                                              <strong>{{ $t("gender") }}</strong>
                                          </v-list-item-title>
                                      </v-list-item-content>

                                      <v-list-item-content align="center">
                                          <v-list-item-title id="table" class="white--text">
                                              <strong>
                                                  {{$t("age")}}
                                              </strong>
                                          </v-list-item-title>
                                      </v-list-item-content>
                                      <v-list-item-content align="center">
                                          <v-list-item-title id="table" class="white--text">
                                              <strong>
                                                  {{$t("country")}}
                                              </strong>
                                          </v-list-item-title>
                                      </v-list-item-content>
                                      <v-list-item-content align="center">
                                          <v-list-item-title id="table" class=" white--text">
                                              <strong>
                                                  {{$t("visited_date")}}
                                              </strong>
                                          </v-list-item-title>
                                      </v-list-item-content>
                                      <v-list-item-content align="center">
                                          <v-list-item-title id="table" class=" white--text">
                                              <strong>
                                                  {{$t("payment_type")}}
                                              </strong>
                                          </v-list-item-title>
                                      </v-list-item-content>
                                  </v-list-item>
                              </v-list>
                          </v-card>
                          <v-card style="overflow-y: scroll; height: 350px;">
                              <v-list v-for="(pCustomerDetails, i) in branchCustomerDetails"
                                      :key="i">
                                  <v-list-item :style="{ background: pCustomerDetails.backgroundColor }">
                                      <v-list-item-avatar>
                                          <v-title id="table" class="black--text">
                                              {{i + 1}}
                                          </v-title>
                                      </v-list-item-avatar>
                                      <v-list-item-content align="center">
                                          <v-list-item-title id="table" class="black--text">
                                              {{ pCustomerDetails.fullName }}
                                          </v-list-item-title>
                                      </v-list-item-content>
                                      <v-list-item-content align="center">
                                          <v-list-item-title id="table" class="mr-18 black--text">
                                              {{ pCustomerDetails.gender }}
                                          </v-list-item-title>
                                      </v-list-item-content>

                                      <v-list-item-content align="center">
                                          <v-list-item-title id="table" class="mr-18 black--text">
                                              {{ pCustomerDetails.age }}
                                          </v-list-item-title>
                                      </v-list-item-content>
                                      <v-list-item-content align="center">
                                          <v-list-item-title id="table" class="mr-18 black--text">
                                              {{ pCustomerDetails.country }}
                                          </v-list-item-title>
                                      </v-list-item-content>
                                      <v-list-item-content align="center">
                                          <v-list-item-title id="table" class="mr-18 black--text">
                                              {{ pCustomerDetails.issuedDate }}
                                          </v-list-item-title>
                                      </v-list-item-content>
                                      <v-list-item-content align="center">
                                          <v-list-item-title id="table" class="mr-18 black--text">
                                              {{ pCustomerDetails.paymentType }}
                                          </v-list-item-title>
                                      </v-list-item-content>
                                  </v-list-item>
                                  <v-divider inset></v-divider>
                              </v-list>
                          </v-card>
                      </v-col>
                      <v-col cols="12" sm="6">
                          <h1 v-if="!isNepaliLanguage" class="headline mt-5 primary--text" style="margin-left:20px">
                              Employees in {{ branchInformation.branchName }}
                          </h1>
                          <h1 v-else class="headline mt-5 primary--text" style="margin-left:20px">
                              {{ branchInformation.branchNameNepali }}मा कर्मचारीहरु
                          </h1>
                          <v-card>
                              <v-list style="background-color: #1976d2">
                                  <v-list-item>
                                      <v-list-item-avatar>
                                          <v-title id="table" class="white--text"><strong>{{ $t("s_no") }}</strong></v-title>
                                      </v-list-item-avatar>
                                      <v-list-item-content>
                                          <v-list-item-title id="table"
                                                             align="center"
                                                             class="white--text">
                                              <strong>{{ $t("employee_name") }}</strong>
                                          </v-list-item-title>
                                      </v-list-item-content>
                                      <v-list-item-content align="center">
                                          <v-list-item-title id="table" class="mr-18 white--text"><strong>{{ $t("address") }}</strong></v-list-item-title>
                                      </v-list-item-content>
                                      <v-list-item-content align="center">
                                          <v-list-item-title id="table" class="white--text">
                                              <strong>
                                                  {{$t("type")}}
                                              </strong>
                                          </v-list-item-title>
                                      </v-list-item-content>
                                      <v-list-item-content align="right">
                                          <v-list-item-title id="table" class="white--text">
                                              <strong>
                                                  {{$t("contact_no")}}
                                              </strong>
                                          </v-list-item-title>
                                      </v-list-item-content>
                                  </v-list-item>
                              </v-list>
                          </v-card>
                          <v-card style="overflow-y: scroll; height: 350px">
                              <v-card style="height: 350px;">
                                  <v-list v-for="(pilgrimageEmployee, i) in pilgrimageEmployeeDetails"
                                          :key="i">
                                      <v-list-item :style="{ background: pilgrimageEmployee.backgroundColor }">
                                          <v-list-item-avatar>
                                              <v-title id="table" class="black--text">
                                                  {{i + 1}}
                                              </v-title>
                                          </v-list-item-avatar>
                                          <v-list-item-content>
                                              <v-list-item-title id="table"
                                                                 align="center"
                                                                 class="black--text">{{ pilgrimageEmployee.empName }}</v-list-item-title>
                                          </v-list-item-content>
                                          <v-list-item-content align="center">
                                              <v-list-item-title id="table"
                                                                 class="mr-18 black--text">{{ pilgrimageEmployee.empAddress }}</v-list-item-title>
                                          </v-list-item-content>
                                          <v-list-item-content align="center">
                                              <v-list-item-title id="table" class="black--text">
                                                  {{ pilgrimageEmployee.empType }}
                                              </v-list-item-title>
                                          </v-list-item-content>
                                          <v-list-item-content align="center">
                                              <v-list-item-title id="table" class="black--text">
                                                  {{ pilgrimageEmployee.empPhone }}
                                              </v-list-item-title>
                                          </v-list-item-content>
                                      </v-list-item>
                                      <v-divider></v-divider>
                                  </v-list>
                              </v-card>
                          </v-card>
                      </v-col>
                  </v-row>
              </v-container>


              <v-container>

                  <v-row>
                      <v-col cols="12" md="6">
                          <v-card class="pa-5" elevation="5">
                              <v-expansion-panels hover>
                                  <v-expansion-panel>
                                      <v-expansion-panel-header>{{$t('filter')}}</v-expansion-panel-header>
                                      <v-expansion-panel-content>
                                          <v-row align="center" class="mt-4">
                                              <v-col class="float-right" cols="12" sm="6">
                                                  <v-menu :close-on-content-click="false"
                                                          :nudge-right="40"
                                                          class="col-sm-6 col-md-4"
                                                          min-width="290px"
                                                          offset-y
                                                          transition="scale-transition"
                                                          v-model="snackbar.menu">
                                                      <template v-slot:activator="{ on }">
                                                          <v-text-field clearable
                                                                        :label="$t('select_date')"
                                                                        prepend-inner-icon="event"
                                                                        readonly
                                                                        dense
                                                                        outlined
                                                                        v-model="serverParams.date"
                                                                        v-on="on"></v-text-field>
                                                      </template>
                                                      <v-date-picker v-model="serverParams.date"></v-date-picker>

                                                  </v-menu>
                                              </v-col>

                                              <v-col class="float-right" cols="12" sm="6">
                                                  <v-autocomplete v-model="serverParams.reportTypeID"
                                                                  :items="monthTypes"
                                                                  clearable
                                                                  dense
                                                                  outlined
                                                                  item-text="value"
                                                                  item-value="id"
                                                                  :label="$t('report_type')">
                                                  </v-autocomplete>
                                              </v-col>

                                          </v-row>
                                          <v-row align="center" justify="center">
                                              <v-btn color="primary" dark v-on:click="searchInput" class="mr-1">
                                                  <i class="material-icons">search</i>{{$t('search')}}
                                              </v-btn>
                                              <v-btn color="error" dark @click="clearInput">
                                                  <i class="material-icons">clear</i>{{$t('clear')}}
                                              </v-btn>
                                          </v-row>
                                      </v-expansion-panel-content>
                                  </v-expansion-panel>
                              </v-expansion-panels>
                              <h1 v-if="!isNepaliLanguage" class="headline mb-2 primary--text" style="border-bottom: 1px solid grey">
                                  Customer Visited in {{ branchInformation.branchName }}
                              </h1>
                              <h1 v-else class="headline mb-2 primary--text" style="border-bottom: 1px solid grey">
                                  {{ branchInformation.branchNameNepali }}मा ग्राहक भ्रमण गरेको
                              </h1>
                              <template>
                                  <div id="app">
                                      <GChart type="ColumnChart"
                                              :data="customerVisitReportData"
                                              :options="chartOptions"
                                              style="height: 400px;" />
                                  </div>
                              </template>
                          </v-card>
                      </v-col>
                      <v-col cols="12" md="6">
                          <v-card class="pa-5" elevation="5">
                              <v-expansion-panels hover>
                                  <v-expansion-panel>
                                      <v-expansion-panel-header>{{$t('filter')}}</v-expansion-panel-header>
                                      <v-expansion-panel-content>
                                          <v-row align="center" class="mt-4">
                                              <v-col class="float-right" cols="12" sm="6">
                                                  <v-menu :close-on-content-click="false"
                                                          :nudge-right="40"
                                                          class="col-sm-6 col-md-4"
                                                          min-width="290px"
                                                          offset-y
                                                          transition="scale-transition"
                                                          v-model="snackbar.menu1">
                                                      <template v-slot:activator="{ on }">
                                                          <v-text-field clearable
                                                                        :label="$t('select_date')"
                                                                        prepend-inner-icon="event"
                                                                        readonly
                                                                        dense
                                                                        outlined
                                                                        v-model="serverParams.dates"
                                                                        v-on="on"></v-text-field>
                                                      </template>
                                                      <v-date-picker v-model="serverParams.dates"></v-date-picker>

                                                  </v-menu>
                                              </v-col>

                                              <v-col class="float-right" cols="12" sm="6">
                                                  <v-autocomplete v-model="serverParams.reportTypeIDs"
                                                                  :items="monthTypes"
                                                                  clearable
                                                                  item-text="value"
                                                                  dense
                                                                  outlined
                                                                  item-value="id"
                                                                  :label="$t('report_type')">
                                                  </v-autocomplete>
                                              </v-col>

                                          </v-row>
                                          <v-row align="center" justify="center">
                                              <v-btn color="primary" dark v-on:click="searchInputTicketSales" class="mr-1">
                                                  <i class="material-icons">search</i>{{$t('search')}}
                                              </v-btn>
                                              <v-btn color="error" dark @click="clearInputTicketSales">
                                                  <i class="material-icons">clear</i>{{$t('clear')}}
                                              </v-btn>
                                          </v-row>
                                      </v-expansion-panel-content>
                                  </v-expansion-panel>
                              </v-expansion-panels>
                              <h1 v-if="!isNepaliLanguage" class="headline mb-2 primary--text" style="border-bottom: 1px solid grey">
                                  Ticket Sales in {{ branchInformation.branchName }}
                              </h1>
                              <h1 v-else class="headline mb-2 primary--text" style="border-bottom: 1px solid grey">
                                  {{ branchInformation.branchNameNepali }}मा टिकट बिक्री भएको
                              </h1>
                              <template>
                                  <div id="app">
                                      <GChart type="ColumnChart"
                                              :data="ticketSalesReportData"
                                              :options="chartOptions"
                                              style="height: 400px;" />
                                  </div>
                              </template>
                          </v-card>
                      </v-col>
                  </v-row>
              </v-container>
              <v-container>
                  <v-row>
                      <v-col cols="12" md="6">
                          <v-card class="pa-5" elevation="5">
                              <h1 v-if="!isNepaliLanguage" class="headline mb-2 primary--text" style="border-bottom: 1px solid grey">
                                  Monthly Visitors in {{ branchInformation.branchName }}
                              </h1>
                              <h1 v-else class="headline mb-2 primary--text" style="border-bottom: 1px solid grey">
                                  {{ branchInformation.branchNameNepali }}मा मासिक आगन्तुकहरू
                              </h1>
                              <template>
                                  <div id="app">
                                      <GChart type="ColumnChart"
                                              :data="customerDatas"
                                              :options="chartOptions"
                                              style="height: 400px;" />
                                  </div>
                              </template>
                          </v-card>
                      </v-col>


                  </v-row>
              </v-container>
          </v-container>
      </template>
    </div>
  </div>
</template>
<script>
    import { mdiClock, mdiStore, mdiNaturePeople, mdiHead } from "@mdi/js";
    import axios from "axios";
    import { mapGetters } from "vuex";
    import publicAxios from "../../../simple-axios";
    export default {
        props: ["btn-text"],
        watch: {
            "serverParams.reportTypeID": {
                handler: function (val) {
                    if (val) {
                        this.loadItemsCustomerVisitReport();
                    }
                },
            },
            "serverParams.reportTypeIDs": {
                handler: function (val) {
                    if (val) {
                        this.loadItemsTicketSalesReport();
                    }
                },
            },
            dialog(val) {
                val || this.close();
            },
            success: {
                handler: function (val, oldVal) {
                    console.log(val, oldVal);
                    this.loadItems();
                },
                deep: true
            }
        },
        computed: {
            ...mapGetters(["getSystemUserData"]),
            isDivision() {
                return this.getSystemUserData.role === "Division";
            },
            isProvinceModule() {
                return this.getSystemUserData.role === "ProvinceModule";
            },
            isAssociation() {
                return this.getSystemUserData.role === "Association";
            },
            isBranchData() {
                return this.getSystemUserData.BranchID;
            },
            isNepaliLanguage() {
                return this.$i18n.locale === "ne";
            },
        },
        data: () => ({
            reload: [],
            dataS: [],
            barsData: [],
            monthTypes: [
                { id: "1", value: "Yearly" },
                { id: "2", value: "Monthly" },
                { id: "3", value: "Daily" }
            ],
            pilgrimageEmployeeDetails: [],
            branchCustomerDetails: [],
            customerVisitReportData: [],
            ticketSalesReportData: [],
            options: {
                height: 450,
                is3d: true,
            },
            callbackResponse: {
                timeout: 6000,
            },
            columns: [
                {
                    label: "S No.",
                    field: "countIndex",
                },
                {
                    label: "Full Name",
                    field: "fullName",
                },
                {
                    label: "gender",
                    field: "gender",
                },
                {
                    label: "Nationality",
                    field: "nationality",
                },
                {
                    label: "Ticket No.",
                    field: "ticketNumber",
                },
                {
                    label: "Issued Date",
                    field: "issuedDate",
                },
                {
                    label: "Actions",
                    field: "actions",
                },
            ],
            rows: [],
            dialogDelete: false,
            deleteFormData: [],
            formdata: [],
            userList: [],
            customerData: {
                totalRows: 0,
                editItemIndex: null,
            },
            customerDatas: [],
            serverParams: {
                search: "",
                columnFilters: {},
                sort: [
                    {
                        field: "personInformationID",
                        type: "desc",
                    },
                ],
                page: 1,
                perPage: 50,
            },
            snackbar: {
                color: "",
                snackbar: false,
                menu: false,
                menu2: false,
                text: "",
                timeout: 6000,
                isLoading: false,
                formModal: false,
                createFormComponent: false,
                editFormComponent: false,
                breadcrumbsItem: [
                    {
                        text: "Dashboard",
                        disabled: false,
                        to: "PilgrimageDashboard",
                        exact: true,
                    },
                    {
                        text: "Pilgrimage Customer Information",
                        disabled: true,
                    },
                ],
                dynamicComponent: {
                    current: "CustomerForm",
                },
            },

            hotelBranchId: [],
            chartData: [],
            chartOptions: {
                chart: {
                    title: "Company Performance",
                    subtitle: "Sales, Expenses, and Profit: 2014-2020",
                },
            },
            barData: [],
            branchInformation: [],
            RoomData: [],
            width: 2,
            radius: 10,
            padding: 8,
            lineCap: "round",
            value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
            fill: false,
            type: "trend",
            autoLineWidth: false,
            svgClock: mdiClock,
            svgStore: mdiStore,
            svgCustomer: mdiNaturePeople,
            svgHeading: mdiHead,
        }),
        components: {},
        methods: {
            getCompleteData() {
                this.loadItems();
                this.getPilgrimageCustomerCountryWise();
                this.getPilgrimageCustomerMonthlyWise();
                this.getBranchEmployeeDetails();
                this.getPieForeignerandDomesticMonthlyPerformance();
                this.dashboardData();
                this.getBranchCustomerDetails();
                this.loadItemsCustomerVisitReport();
                this.loadItemsTicketSalesReport();
            },
            fullName(rowObj) {
                return rowObj.firstName + "   " + rowObj.lastName;
            },
            searchInput() {
                this.loadItemsCustomerVisitReport();
            },
            clearInput() {
                this.serverParams.reportTypeID = null
                this.serverParams.date = null
                this.loadItems();
                this.loadItemsCustomerVisitReport();
            },
            searchInputTicketSales() {
                this.loadItemsTicketSalesReport();
            },
            clearInputTicketSales() {
                this.serverParams.reportTypeIDs = null
                this.serverParams.dates = null
                this.loadItems();
                this.loadItemsTicketSalesReport();
            },
            formResponse(data) {
                console.log({ data });
                if (data.message) {
                    this.snackbar.formModal = false;
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.callbackResponse = data;
                    this.snackbar.snackbar = true;
                    this.reloadPage();
                    this.loadItems();
                } else {
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.snackbar.formModal = false;
                }
            },
            onResponse(data) {
                if (data == true) {
                    this.dialogDelete = false;
                    this.loadItems();
                } else {
                    this.dialogDelete = false;
                }
            },
            dialogueClose() {
                this.customerData.editItemIndex = null;
                this.snackbar.formModal = false;
                this.snackbar.createFormComponent = false;
                this.snackbar.editFormComponent = false;
            },
            async loadItems() {
                this.snackbar.isLoading = true;
                let param = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    Country: this.serverParams.columnFilters.country,
                    FirstName: this.serverParams.columnFilters.firstName,
                    CitizenShipNumber: this.serverParams.columnFilters.citizenShipNumber,
                    PassportNumber: this.serverParams.columnFilters.passportNumber,
                    LicenseNumber: this.serverParams.columnFilters.licenseNumber,
                };
                axios
                    .post("NationalPark/GetDailyCustomerInformationListAsync", param)
                    .then((response) => {
                        this.rows = response.data.data;
                        this.totalRecords = response.data.totalCount;
                        this.snackbar.loading = false;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            async loadItemsCustomerVisitReport() {
                    this.isLoading = true
                    let param = {

                        ReportTypeID: this.serverParams.reportTypeID != null && this.serverParams.reportTypeID != undefined ? parseInt(this.serverParams.reportTypeID) : 0,
                        Date: this.serverParams.date,
                    };
                    await axios.post('Pilgrimage/GetPilgrimageCustomerVisitReport', param)
                        .then(response => {

                            this.customerVisitReportDataList = response.data;
                        })
                        .catch(err => {
                            console.log(err)
                        })
                    console.log('yaa', this.customerVisitReportDataList)
                    let counta = this.customerVisitReportDataList.length;

                    if (counta == 0 || counta == 'undefined') {
                        this.customerVisitReportData = [];
                    } else {
                        let formatedData = this.getChartData(this.customerVisitReportDataList);
                        this.customerVisitReportData = formatedData;
                    }
            },
            async loadItemsTicketSalesReport() {
                    this.isLoading = true
                    let param = {

                        ReportTypeID: this.serverParams.reportTypeIDs != null && this.serverParams.reportTypeIDs != undefined ? parseInt(this.serverParams.reportTypeIDs) : 0,
                        Date: this.serverParams.dates,
                    };
                   await axios.post('Pilgrimage/GetPilgrimageTicketSalesReport', param)
                        .then(response => {

                            this.ticketSalesReportDataList = response.data;
                        })
                        .catch(err => {
                            console.log(err)
                        })
                    console.log('yaa', this.ticketSalesReportDataList)
                    let counta = this.ticketSalesReportDataList.length;

                    if (counta == 0 || counta == 'undefined') {
                        this.ticketSalesReportData = [];
                    } else {
                        let formatedData = this.getChartData(this.ticketSalesReportDataList);
                        this.ticketSalesReportData = formatedData;
                    }
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({ page: params.currentPage });
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({ perPage: params.currentPerPage });
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [
                        {
                            type: params[0].type,
                            field: params[0].field,
                        },
                    ],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            addModal() {
                this.snackbar.formModal = true;
                this.snackbar.createFormComponent = true;
            },
            editItem(item) {
                this.snackbar.formModal = true;
                this.snackbar.editFormComponent = true;
                this.customerData.editItemIndex = item.personInformationID;
            },
            deleteItem(props) {
                this.dialogDelete = true;
                this.deleteFormData.url =
                    "NationalPark/DeleteCustomerInformationByIDAsync/" +
                    props.personInformationID;
            },

            async dashboardData() {
                const dashboardData = await axios.get(
                    "Branch/GetBranchInformationByBranchID"
                );

                this.branchInformation = dashboardData.data;
                console.log("this.branchDetails", this.branchInformation);
            },

            async getPieForeignerandDomesticMonthlyPerformance() {
                const { data } = await publicAxios.post(
                    "Airport/GetAirportDepartureFlights"
                );
                let formatedData = this.getChartData(data);
                this.dataS = formatedData;
                console.log("this.PieChartData", this.dataS);
            },
            async getPilgrimageCustomerCountryWise() {
                const { data } = await publicAxios.get(
                    "Pilgrimage/GetPilgrimageCustomerCountrywisecountAsync"
                );
                let formatedData = this.getChartData(data);
                this.barsData = formatedData;
                console.log("this.GetPilgrimageCustomerCountrywisecountAsync", this.barsData);
            },
            async getPilgrimageCustomerMonthlyWise() {
                const { data } = await publicAxios.get(
                    "Pilgrimage/GetPilgrimageCustomerMonthWise"
                );
                let formatedData = this.getChartData(data);
                this.customerDatas = formatedData;
                console.log("this.GetNationalParkCustomerMonthWise", this.customerDatas);
            },
            async getBranchCustomerDetails() {
                const { data } = await publicAxios.get(
                    "Pilgrimage/GetBranchPilgrimageCustomer/0"
                );
                this.branchCustomerDetails = data;
                console.log("this.branchCustomerDetails", this.branchCustomerDetails);
            },
            async getBranchEmployeeDetails() {
                const { data } = await publicAxios.post(
                    "Aviation/GetAirEmployeeDetails/0"
                );
                this.pilgrimageEmployeeDetails = data;
                console.log("this.airEmployeeDetails", this.getBranchEmployeeDetails);
            },
            getChartData(data) {
                console.log(data);
                let arr = [];
                arr[0] = Object.keys(data[0]);
                for (let i = 1; i < data.length + 1; i++) {
                    arr[i] = Object.values(data[i - 1]);
                }
                return arr;
            },
            reloadPage() {
                this.getCompleteData();
                this.reload += 1;
                //this.$router.go();
            },
        },
        created() {
           
        },
        mounted() {
            //this.loadItems();
            this.getCompleteData();
        },
    };
</script>
<style scoped>
::-webkit-scrollbar {
  width: 3px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.v-progress-circular {
  margin: 1rem;
}

    @media screen and (min-width:1903px) {
        #sideBox {
            font-size: 16px;
            color: white;
        }

        #titleForBox {
            display: none;
            text-align: center;
        }

        .boxIcon {
            font-size: 36px;
        }

        #hotellogo {
            width: 100%;
            height: 550px;
            object-fit: fill;
        }

        #box {
            height: 375px;
        }

        #MainContainer {
            margin-left: 90px;
        }
    }

    @media screen and (max-width:1903px) {
        #sideBox {
            font-size: 16px;
            color: white;
        }

        #titleForBox {
            display: none;
            text-align: center;
        }

        #table {
            font-size: 12px !important;
        }

        .boxIcon {
            font-size: 26px;
        }

        #hotellogo {
            width: 890px;
            height: 550px;
            object-fit: fill;
        }

        #box {
            height: 375px;
        }

        #MainContainer {
            margin-left: 90px;
        }
    }

    @media screen and (max-width:1263px) {
        #sideBox {
            font-size: 12px;
            color: white;
        }

        .boxIcon {
            font-size: 20px;
        }

        #box {
            height: 305px;
        }

        #table {
            font-size: 10px !important;
        }

        #sideMainBox {
            height: 80px;
        }

        #table {
            font-size: 12px !important;
        }

        #hotellogo {
            width: 650px;
            height: 550px;
            object-fit: fill;
        }

        #MainContainer {
            margin-left: 90px;
        }
    }


    @media screen and (max-width:979px) {
        #sideBox {
            font-size: 10px;
            color: white;
        }

        #sideMainBox {
            height: 70px;
        }

        #box {
            height: 270px;
        }

        #table {
            font-size: 10px !important;
        }

        #hotellogo {
            width: 100%;
            height: 550px;
            object-fit: fill;
        }

        #MainContainer {
            margin-left: 70px;
        }
    }

    @media screen and (max-width: 860px) {
        #sideBox {
            display: none;
        }

        #table {
            font-size: 10px !important;
        }

        #sideMainBox {
            height: 70px;
            width: 100%;
            padding: 25px;
        }

        .boxIcon {
            /*  font-size: 26px;
            margin-bottom:15px;*/
            display: none;
        }

        #titleForBox {
            color: white;
            display: block;
            text-align: center;
        }

        #box {
            width: 100%;
            height: 320px;
        }

        #hotellogo {
            width: 100%;
            height: 550px;
            object-fit: fill;
        }

        .col-12 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }

        #MainContainer {
            margin-left: 40px;
        }
    }

    @media screen and (max-width: 750px) {
        #sideBox {
            display: none;
        }

        #table {
            font-size: 10px !important;
        }

        #sideMainBox {
            height: 70px;
            width: 100%;
        }


        #box {
            width: 100%;
            height: 350px;
        }

        #hotellogo {
            width: 100%;
            height: 450px;
            object-fit: fill;
        }

        .col-12 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }

        #MainContainer {
            margin-left: 40px;
        }
    }

    @media screen and (max-width:640px) {
        #sideBox {
            display: none;
        }

        #table {
            font-size: 10px !important;
        }

        #hotellogo {
            width: 100%;
            height: 350px;
            object-fit: fill;
        }
    }


    @media screen and (max-width:599px) {
        #sideBox {
            display: none;
        }

        #table {
            font-size: 10px !important;
        }

        #hotellogo {
            width: 100%;
            height: 250px;
            object-fit: fill;
        }
    }
</style>
